import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { getQueryParamByName } from '../common/utils';
import { SESSION_HEADER } from '../models/session';
/**
 * This class is for intercepting http requests.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  sessionId: string;

  constructor(private sessionService: SessionService) { 
    this.sessionId = (this.sessionService.sessionId || getQueryParamByName('jSession'));
  }

  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        [SESSION_HEADER]: this.sessionId,
        'Cache-Control': 'no-cache'
      }
    });

    return next.handle(request)
      .pipe(catchError((err) => {
        if (err.status === 408) {
          // redirect to the login route
          // or show a modal
          if(this.sessionService.startCheckingSessionExpiry) {
            this.sessionService.sessionExpiredSubject.next(true);
          }
        }
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
        }
        return evt;
      }));
  }
}

