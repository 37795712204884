<div class="accepted_logos">
    <div class="accepted_title">
        <h3 class="accepted_title__header font_family__standard">Accepted Payment Types</h3>
    </div>
    <div class="accpt_pymnt__types">
        <ul class="align__payment_types_acptnc no-bullets" *ngIf="gd?.oscConfig?.ccPaymentEnabled">
            <li *ngIf="gd?.oscConfig?.visaEnabled && showCreditcardType('Visa')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsvisa-copy.png" alt="Visa" />
            </li>
            <li *ngIf="gd?.oscConfig?.masterCardEnabled && showCreditcardType('MasterCard')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsmastercard-copy.png" alt="MasterCard" />
            </li>
            <li *ngIf="gd?.oscConfig?.amexEnabled && showCreditcardType('Amex')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsamex-copy.png" alt="American Express" />
            </li>
            <li *ngIf="gd?.oscConfig?.discoverEnabled && showCreditcardType('Discover')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsdiscover-copy.png" alt="Discover" />
            </li>
            <li *ngIf="gd?.oscConfig?.jcbEnabled && showCreditcardType('JCB')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsjcb-copy.png" alt="JCB" />
            </li>
            <li *ngIf="gd?.oscConfig?.unionPayEnabled && showCreditcardType('UnionPay')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsunionpay-copy.png" alt="UnionPay" />
            </li>
            <div *ngIf="gd?.oscConfig?.dinersClubEnabled&& showCreditcardType('Diners Club')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsdinersclub-copy.png" alt="Diners Club" />
            </div>
            <li *ngIf="gd?.oscConfig?.rupayGlobalEnabled && showCreditcardType('RuPay Global')">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsrupay-copy.png" alt="RuPay" />
            </li>
            <li *ngIf="paypalEnabled">
                <!-- PayPal Logo -->
                <img class="pymnt_cc_amex_logo paypal_logo" src="../../assets/img/PP_Acceptance_Marks_for_LogoCenter_150x94.png" alt="Paypal" />
                <!-- PayPal Logo -->
            </li>
        </ul>
            <!-- <div class="col-md-1 col-2 d-flex justify-content-center ms-2 pt-3 text-nowrap Alipay-Acceptance" *ngIf="alipayEnabled">
          ....& more
          </div> -->
      <ul>
        <li *ngIf="alipayEnabled" class="alipay-con no-bullets">
            <img class="pymnt_cc_amex_logo__alipay" src="../../assets/img/AlipayPlusAcceptance.png" alt="Alipay+" />
        </li>
      </ul>
    </div>
</div>
