import {Component, ViewChild} from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { GlobalAppData } from '../models/global-app-data';
import { Constants } from '../constants/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cvv-tooltip',
  templateUrl: './cvv-tooltip.html',
  styleUrls: ['./cvv-tooltip.css']
})
export class NgbdTooltipCustomclass {
    
    @ViewChild('cvvModalPopUp', { static: false }) private cvvModal;

    faQuestionCircle = faQuestionCircle

    hoverTooltip:string = ''

    oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
    
    constructor(public gd:GlobalAppData,
      private modalService: NgbModal
    ) {
    }

    loadingTooltip(){
      this.modalService.open(this.cvvModal, { ariaLabelledBy:'cvv-dialog-title', backdrop:  'static', keyboard :  true}).result.then((result) => {
      })
    } 
    openModal(){
      document.getElementById('exampleModal').style.display = 'block'
    }
    closeModal(){
      document.getElementById('tooltip_modal').style.display='none';
    }
  }
