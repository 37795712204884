import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class Constants {
    public static readonly ZIPCODE_US_VALIDATOR_PATTERN = '^0?[0-9]+$';
    public static readonly ZIPCODE_CA_VALIDATOR_PATTERN = '^[_A-z0-9\s]*([_A-z0-9\s])*$';
    public static readonly PHONENUMBER_VALIDATOR_PATTERN = '^[0-9]+$';
    public static readonly EMAIL_VALIDATOR_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    public static readonly MOBILE_DETECT_IOS_PATTERN = /iPhone|iPad|iPod/i
    public static readonly MOBILE_DETECT_ANDROID_PATTERN = /Android|BlackBerry|Windows Phone|webOS|IEMobile|Opera Mini/i
    
    // Payment UI styles Based on OSC Codes
    public static readonly OSC_SYSTEM_CODES = ["ERT", "ETU", "PTU", "TOT", "SST"];
}
