import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OscConfig } from '../models/osc-config';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OscConfigService {
  constructor(private http: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  getOscConfigForHop(oscCode, hopId): Observable<OscConfig> {

    return this.http.get<OscConfig>(environment.PAYMENT_UI + `/epms/config/osc/${oscCode}/hop/${hopId}`, this.httpOptions);
  }

  updateModeOfReceipt(transactionId: string, modeOfReceiptType: string): Observable<string> {
    return this.http.post<string>(`${environment.PAYMENT_UI}/epms/receipt/${transactionId}`, {"modeOfReceiptType": modeOfReceiptType}, this.httpOptions);
  }
}
