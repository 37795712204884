<div [ngClass]="oscCodes.includes(gdSession?.oscConfig?.oscCode)?'tofel-modal-header':'modal-header'">
    <h3 mat-dialog-title [ngClass]="oscCodes.includes(gdSession?.oscConfig?.oscCode)?'tofel-dialog-title':'mat-mdc-dialog-title mdc-dialog__title'">Alipay+</h3>
    <button mat-icon-button class="close-button" aria-label="close" (click)="exit()">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <p>After clicking on the Alipay+ payment button, you will be presented with the option to pay in your local currency
        using any one of many popular local e-wallets preferred by customers in China, Hong Kong, Indonesia, Malaysia,
        the Philippines, South Korea, and Thailand including but not limited to:
    </p>
    <ul style="list-style-type: none">
        <li>AlipayChina (China)</li>
        <li>AlipayHK (Hong Kong, SAR)</li>
        <li>KaKao Pay (South Korea)</li>
        <li>Kredivo (Indonesia)</li>
        <li>Gcash (The Philippines)</li>
        <li>Touch'n Go (Malaysia)</li>
        <li>Rabbit Line Pay (Thailand)</li>
        <li>Truemoney (Thailand)</li>
        <li>Dana (Indonesia)</li>
        <li>Akulaku Paylater (Indonesia)</li>
        <li>Akulaku Paylater (Philippines)</li>
    </ul>
    <p>Support for additional e-wallets, payment methods and countries may also be expanded in the future.
    </p>
    <ul class="row pt-5 no-bullets">
        <li class="col-md-2 col-8 pb-4">
            <img class="alipay" src="../../assets/img/AlipayPlus.png" alt="Alipay+" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/alipay.png" alt="Alipay" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/alipayhk.png" alt="Alipay HK" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/kakoapay.png" alt="KaKao Pay" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/gcash.png" alt="Gcash" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/touchngo.png" alt="Touch 'n Go eWallet" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/rabbitlinepay.png" alt="Rabbit LINE Pay" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/truemoney.png" alt="Truemoney" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/dana.jpg" alt="DANA" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/akulakuPaylater.png" alt="Akulaku Paylater" />
        </li>
    </ul>
</mat-dialog-content>
