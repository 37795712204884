<div class="container-xl mt-4">
  <div class="card col-12 light-blue mt-lg-5">
    <div class="card-body mt-lg-5 ms-lg-5">
      <app-merchant-response></app-merchant-response>
      <h3 *ngIf="validTransaction">Your Order is in Progress</h3>
      <p *ngIf="!validTransaction">
        Sorry, but we cannot complete your payment because too much time has
        passed or there was another technical issue. Please return to the site
        where you were attempting to place your order.
      </p>
      <div *ngIf="validTransaction" class="mt-lg-5 ms-lg-4 mt-4 ms-2">
        <p>
          We are contacting your bank to process your payment, and we are
          awaiting for their response. This may take up to 8 minutes.
        </p>
        <p>
          You can wait on this page for the response, or close your browser and
          check your ETS account later.
        </p>
        <b [class]="hideTimer ? 'text-danger fs-5' : ''"><span *ngIf="hideTimer">Processing is taking longer than expected.</span>
          Do not attempt to resubmit your order and payment.</b>
      </div>
      <div *ngIf="!hideTimer && validTransaction" id="countdown">
        <svg>
          <circle r="72" cx="80" cy="80" stroke="#003082"></circle>
        </svg>
        <div id="countdown-number"></div>
      </div>
    </div>
  </div>
</div>