<footer [ngClass]="oscCodes.includes(spcOscCode)?'tofel-footer footer-align':'footer-align'">
  <div class="container-fluid footer-text-align font_family__standard">
    <p class="line__font_text font_family__standard">
      <span *ngIf="oscCodes.includes(spcOscCode)">
        <a id="#footer" href="https://www.ets.org/contact/testing-programs/toefl.html" target="_blank">Contact Us</a>
        <span class="pipe-color">&nbsp;|&nbsp;</span>
      </span>
      <a id="#footer" href="https://www.ets.org/legal" target="_blank">Legal</a>
      <span class="pipe-color">&nbsp;|&nbsp;</span>
      <a href="https://www.ets.org/legal/privacy" target="_blank">Privacy &amp; Security</a>
      <span class="pipe-color">&nbsp;|&nbsp;</span>
      <a href="https://www.ets.org/legal/trademarks/owned" target="_blank">ETS Trademarks</a>
      <span *ngIf="oscCodes.includes(spcOscCode)">
        <span class="pipe-color">&nbsp;|&nbsp;</span>
        <a href="https://get.adobe.com/reader/" target="_blank">Get Adobe Reader</a>&nbsp;(for PDFs)
      </span>
    </p>
    <p>Copyright © 2024 by Educational Testing Service. All rights reserved.
      All trademarks are the property of their respective owners.</p>
  </div>
</footer>