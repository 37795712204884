import { Component, Input } from '@angular/core';
import { Transaction } from '../models/transaction';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {GlobalAppData} from '../models/global-app-data';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.css']
})
export class OrderInfoComponent {
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  @Input() orderData: Transaction;
  faDown = faArrowDown;
  faUp = faArrowUp;
  caret_expand:string = 'caret_expand'
  shpngcontct:string = ''
  caret_expand_lrgscrn:string = 'caret_expand_lrgscrn'
  shpngcontct_mobile:string = ''
  caret_expand_lrgscrn_collapse = ''
  
  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
  @Input() spcOscCode: String;

  constructor(public gd:GlobalAppData) {
  }

  expandShippingInfo(){
    this.shpngcontct = 'shpngcontct'
    this.caret_expand_lrgscrn = 'shpngcontct_mobile'
    this.shpngcontct_mobile = 'shpngcontct_mobile'
    this.caret_expand_lrgscrn_collapse = 'caret_expand_lrgscrn_collapse'
    this.caret_expand = ''
  }
  collapseShippingInfo(){
    this.caret_expand = 'caret_expand'
    this.shpngcontct = ''
    this.caret_expand_lrgscrn = 'shpngcontct_mobile'
    this.shpngcontct_mobile = ''
    this.caret_expand_lrgscrn_collapse = ''
  }
}
