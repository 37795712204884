export enum TransactionProgress {
    countDownMinutes = 8,
    intervalInSecondsToGetStatus = 60,
    intervalToGetStatusAfterTimeout = 15,
    countDownMinAfterTimeout = 2
}

export enum TransactionStatus {
    awaited = 'awaited',
    inProgress = 'in_progress',
    success = 'success',
    error = 'error'
}