import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Country} from '../models/country';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryRegionService {
  countryList = [];
  constructor(private http: HttpClient) {
  }

  getCountries() {
    return this.countryList.map(country => {
      return {name: country.countryName, id: country.countryIsoCode};
    });
  }

  loadUsaStates() {
    const states = this.countryList.filter(cntry => cntry.countryIsoCode === 'US')[0].stateProvince;
    return of(states);
  }

  loadCountryStates(country: string) {
    const states = this.countryList.filter(cntry => cntry.countryIsoCode === country)[0].stateProvince;
    return states.map(d => {
      return { name: d.stateName, id: d.stateCode };
    });
  }

  loadCaStates() {
    const states = this.countryList.filter(cntry => cntry.countryIsoCode === 'CA')[0].stateProvince;
    return of(states);
  }

  loadCountries() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.http.get<Country[]>(environment.PAYMENT_UI + `/epms/countries`, httpOptions).subscribe({next:data => {
      const usaCountry = data.filter(cntry => cntry.countryIsoCode === 'US');
      const nonUsaCountry = data.filter(cntry => cntry.countryIsoCode !== 'US');
      this.countryList = [...usaCountry, ...nonUsaCountry];
      // this.countryList = data;
      sessionStorage.setItem('countries', (JSON.stringify(this.countryList)));
    }, error:err => {
      console.log('error while loading the countries');
      this.countryList = [];
      }});
  }
}
