import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { MerchantResponseComponent } from '../merchant-response/merchant-response.component';
import { Transaction } from '../models/transaction';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent {
  @Input() orderData: Transaction;
  @ViewChild(MerchantResponseComponent) public merchantResponseComponent;
  @ViewChild('expandBtn') expandBtn: ElementRef;
  @ViewChild('collapseBtn') collapseBtn: ElementRef;
  faDown = faArrowDown;
  faUp = faArrowUp;
  expanddetails: boolean = false;
  collapsable_line__items_condition: string = ' collapsable_line__items';
  @Input() spcOscCode: String;

  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;

  constructor() {
    console.log('[ order data ]', this.orderData);
  }

  modifyYourOrder() {
    this.merchantResponseComponent.backToOrder('backToOrder', '', {});
  }

  expandDetails(val: boolean) {
    this.expanddetails = val;
    this.collapsable_line__items_condition = '';
  }

  collapseDetails(val: boolean) {
    this.collapsable_line__items_condition += ' collapsable_line__items';
    this.expanddetails = val;
  }

  expandDetailsOnKey(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); 
      this.expandDetails(true);
      
      setTimeout(() => {
        var staticContent = document.getElementById('panel-1');
        staticContent.focus();
       }, 10);
    }
  }
  
  collapseDetailsOnKey(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.collapseDetails(false);
   
      setTimeout(() => {
        this.expandBtn.nativeElement.focus();
      }, 10);
    }
  }
  

  splitDesc(desc){
    if(desc){
      return desc.split("||");
    }
  }
}
