import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Constants } from '../constants/constants';
import { GlobalAppData } from '../models/global-app-data';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-alipay-learn-more',
  templateUrl: './alipay-learn-more.component.html',
  styleUrls: ['./alipay-learn-more.component.css']
})
export class AlipayLearnMoreDialogComponent implements OnInit {
  
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
  
  constructor(public dialogRef: MatDialogRef<AlipayLearnMoreDialogComponent>,public gdSession:GlobalAppData){
    dialogRef.disableClose = true;
  }


  ngOnInit(): void {
  }
  exit() {
    this.dialogRef.close();
  }
}
