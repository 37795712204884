import { Injectable } from '@angular/core';
import { Constants } from '../constants/constants';
import { Address } from '../models/address';
import { Contact } from '../models/contact';
import { Payment } from '../models/payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestService {
  constructor() {}

  getBillingPaymentRequest(billingInformation, terminalType?) {
    let request = {} as Payment;
    const billingAdress = {} as Address;
    billingAdress.firstName = billingInformation
      .get('nameDetails.firstName')
      ?.value?.trim();
    billingAdress.lastName = billingInformation
      .get('nameDetails.lastName')
      ?.value?.trim();
    billingAdress.addressLine1 = billingInformation.get(
      'addressDetails.streetAddress1'
    )?.value;
    billingAdress.addressLine2 = billingInformation.get(
      'addressDetails.streetAddress2'
    )?.value;
    billingAdress.city = billingInformation.get('addressDetails.city')?.value;
    billingAdress.company = billingInformation.get(
      'addressDetails.company'
    )?.value;
    billingAdress.country = billingInformation.get(
      'addressDetails.country'
    )?.value;
    billingAdress.postalCode = billingInformation.get(
      'addressDetails.zipcode'
    )?.value;
    billingAdress.region = billingInformation.get(
      'addressDetails.region'
    )?.value;
    // assign both billing and shipping address here
    request.billingAddress = billingAdress;
    // prepare contact and assign to order
    const billingContact = {} as Contact;
    if (billingInformation.get('addressDetails.email')?.value) {
      billingContact.email = billingInformation.get(
        'addressDetails.email'
      )?.value;
    }
    if (billingInformation.get('addressDetails.phoneNumber')?.value) {
      billingContact.phoneNumber = billingInformation.get(
        'addressDetails.phoneNumber'
      )?.value;
    }
    // assign both shipping and billing contact here
    if (billingContact) {
      request.billingContact = billingContact;
    }
    if (terminalType) {
      request = this.detectDeviceType(request);
    }
    return request;
  }

  detectDeviceType(request) {
    if (Constants.MOBILE_DETECT_IOS_PATTERN.test(navigator.userAgent)) {
      request.terminalType = 'WAP';
      request.osType = 'IOS';
    } else if (
      Constants.MOBILE_DETECT_ANDROID_PATTERN.test(navigator.userAgent)
    ) {
      request.terminalType = 'WAP';
      request.osType = 'ANDROID';
    } else {
      request.terminalType = 'WEB';
    }
    return request;
  }
}
