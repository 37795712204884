<div class="secure__row">
  <!-- <mat-icon class="mat_icon__lock">lock_close</mat-icon> -->
  <img class="mat_icon__lock" src="../../assets/svg/securityLock.svg" alt="secured_lock" aria-hidden="true" />
  <h1 class="secure_cehckout__description font_family__narrow">Secure Checkout</h1>
</div>
<div class="secure__row_mobile">
  <!-- <mat-icon class="mat_icon__lock">lock_close</mat-icon> -->
  <img class="mat_icon__lock" src="../../assets/svg/securityLockMob.svg" alt="secured_lock" aria-hidden="true" />
  <h1 class="secure_cehckout__description font_family__narrow">Secure Checkout</h1>
</div>
