<div [ngClass]="oscCodes.includes(spcOscCode)?'tofel-rounded-card-checkout':'rounded-card-checkout'">
    <h2 class="checkout_li_title font_family__narrow">Order Summary</h2>
    <div class="checkout_lineitems_count" *ngIf="orderData?.lineItems?.length > 0">
        <h3 class="checkout_lineitems_count_info font_family__standard">
          {{ orderData?.lineItems.length }} Item(s)
        </h3>
    </div>
    <section *ngIf="orderData?.lineItems?.length > 0">
        <div class="card__lineitem_details" *ngIf="expanddetails === false">
            <p class="registration__title font_family__standard">
                {{ orderData?.lineItems[0]?.productName }}
            </p>
            <div class="registration_title_desc font_family__standard">
              <p *ngFor="let desc of splitDesc(orderData?.lineItems[0]?.productDescription)">
                {{ desc }}
              </p>
            </div>
          <dl class="dl-con">
            <dt>Qty:</dt>
            <dd>{{ orderData?.lineItems[0]?.quantity }}</dd>
            <dt>Unit Price:</dt>
            <dd>${{ orderData?.lineItems[0]?.unitPrice | number : "1.2-2" }}</dd>
            <dt>Tax:</dt>
            <dd><ng-container *ngIf="orderData?.lineItems[0]?.taxAmount"> ${{ orderData?.lineItems[0]?.taxAmount | number : "1.2-2" }} </ng-container></dd>
            <dt>Unit Total:</dt>
            <dd>${{ orderData?.lineItems[0]?.total| number : "1.2-2" }}</dd>
          </dl>
        </div>
        <div *ngFor="let lineItem of orderData?.lineItems;index as i">
            <div class="card__lineitem_details" aria-labelledby="tab-{{i}}" role="tabpanel" *ngIf="expanddetails !== false">
                <p class="registration__title font_family__standard" id="panel-{{i}}"  tabindex="0">
                    {{ lineItem?.productName }}
                </p>
                <div class="registration_title_desc font_family__standard">
                  <p *ngFor="let desc of splitDesc(lineItem?.productDescription)">
                    {{ desc }}
                  </p>
                </div>
              <dl class="dl-con">
                <dt>Qty:</dt>
                <dd>{{ lineItem?.quantity }}</dd>
                <dt>Unit Price: </dt>
                <dd>${{ lineItem?.unitPrice | number : "1.2-2" }}</dd>
                <dt>Tax:</dt>
                <dd><ng-container *ngIf="lineItem?.taxAmount"> ${{ lineItem?.taxAmount | number : "1.2-2" }} </ng-container></dd>
                <dt>Unit Total: </dt>
                <dd>${{ lineItem?.total| number : "1.2-2" }}</dd>
              </dl>
            </div>
        </div>
    </section>
    <div id = "expand" #expandBtn class="expand_icon_details" role="button" 
        id="tab-0" aria-controls="panel-1" aria-expanded="false" aria-orientation="vertical" *ngIf="orderData?.lineItems?.length > 1 && expanddetails === false" (click)="expandDetails(true)" (keydown)="expandDetailsOnKey($event)" tabindex="0">
        <div>
            <span class="details__expand font_family__standard">Expand Details</span>
        </div>
        <img class="checkout__down_angle" src="../../assets/svg/caret_down.svg" alt="down" />
    </div>
    <div id = "collapse" id="tab-1" aria-controls="panel-0" #collapseBtn class="expand_icon_details" role="button" aria-expanded="true" *ngIf="orderData?.lineItems?.length > 1 && expanddetails !== false" (click)="collapseDetails(false)" (keydown)="collapseDetailsOnKey($event)" tabindex="0">
        <div>
            <span class="details__expand font_family__standard">Collapse Details</span>
        </div>
        <img class="checkout__down_angle" src="../../assets/svg/caret_up.svg" alt="up" />
    </div>
    <div class="checkout__total_amount_details">
      <dl class="dl-con">
        <dt class="total__title font_family__standard">TOTAL</dt>
        <dd class="total__title font_family__standard doller_total__title">${{ orderData?.amount | number : "1.2-2" }} <span class="total_title_usd font_family__standard">USD</span></dd>
      </dl>
    </div>
</div>

<!-- <div *ngIf="orderData?.lineItems?.length>0" class="card rounded-card mb-4">
    <div class="card-body card-body-responsive">
        <div class="checkout__dektop_mini">
            <table [ngClass]="orderData.lineItems.length > 2
                    ?
                    'table table-responsive-sm scroll__on_line_items':'table table-responsive-sm scroll__on_line_items__no_scroll'">
                <tr class="border_bottom_header table__rows_align">
                    <th class="no-border first__th_header_order_summary">
                        <h2 class="first__table_header_val">Order Summary</h2>
                    </th>
                    <th class="no-border text-end second__th_header">
                        <span class="text__font_header_td">Unit Price</span>
                    </th>
                    <th class="no-border text-end third__th_header">
                        <span class="text__font_header_td">Tax</span>
                    </th>
                    <th class="no-border text-end fourth__th_header">
                        <span class="text__font_header_td">Total</span>
                    </th>
                </tr>
                <tr class="dotted table__rows_align tr__dotted_bottom" *ngFor="let lineItem of orderData.lineItems">
                    <th class="no-border first__th_header_order_summary">
                        <div class="description__text_line_item description_with_margin">{{lineItem?.productName}}</div>
                        <div class="line__item_quantity description_with_margin">
                          <span class="ps-2">Qty:</span>
                          <span class="line__item_qty_margin">{{lineItem?.quantity}}</span>
                        </div>
                    </th>
                    <th class="no-border text-end second__th_header">
                        <span class="text__font_header_td">{{lineItem?.unitPrice | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end third__th_header">
                        <span class="text__font_header_td">{{lineItem?.taxAmount | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end fourth__th_header">
                        <span class="text__font_header_td">{{lineItem?.total | number : '1.2-2'}}</span>
                    </th>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless top ps-0">
                        <span class="text__font_header_td">Subtotal</span>
                    </td>
                    <td class="borderless top pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.subtotalAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless bottom ps-0">
                        <span class="text__font_header_td">Tax</span>
                    </td>
                    <td class="borderless bottom pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.taxAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="checkout__mobile__view">
            <table [ngClass]="orderData.lineItems.length > 2
                    ?
                    'table table-responsive-sm scroll__on_line_items':'table table-responsive-sm scroll__on_line_items__no_scroll'">
                <tr class="border_bottom_header table__rows_align">
                    <th class="no-border first__th_header_order_summary">
                        <h2 class="first__table_header_val">Order Summary</h2>
                    </th>
                    <th class="no-border text-end second__th_header"><span class="text__font_header_td">Unit Price</span></th>
                    <th class="no-border text-end third__th_header"><span class="text__font_header_td">Tax</span></th>
                    <th class="no-border text-end fourth__th_header"><span class="text__font_header_td">Total</span></th>
                </tr>
                <tr class="table__rows_align tr__dotted_bottom" *ngFor="let lineItem of orderData.lineItems">
                    <th class="no-border first__th_header_order_summary">
                        <div class="description__text_line_item">{{lineItem?.productName}}</div>
                        <div class="line__item_quantity">
                          <span class="ps-2">Qty:</span>
                          <span class="line__item_qty_margin">{{lineItem?.quantity}}</span>
                        </div>
                    </th>
                    <th class="no-border text-end second__th_header">
                        <span class="text__font_header_td">{{lineItem?.unitPrice | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end third__th_header">
                        <span class="text__font_header_td">{{lineItem?.taxAmount | number : '1.2-2'}}</span>
                    </th>
                    <th class="no-border text-end fourth__th_header">
                        <span class="text__font_header_td">{{lineItem?.total | number : '1.2-2'}}</span>
                    </th>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless top ps-0">
                        <span class="text__font_header_td">Subtotal</span>
                    </td>
                    <td class="borderless top pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.subtotalAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
                <tr class="sub-total table__rows_align">
                    <td class="first-col borderless bottom ps-0">
                        <span class="text__font_header_td">Tax</span>
                    </td>
                    <td class="borderless bottom pe-0" colspan="3">
                        <span class="text__font_header_td">{{orderData.amountDetail.taxAmount | number : '1.2-2'}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<app-merchant-response></app-merchant-response> -->
