import { Component, Input } from '@angular/core';
import {  faLock } from '@fortawesome/free-solid-svg-icons';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-secure-checkout',
  templateUrl: './secure-checkout.component.html',
  styleUrls: ['./secure-checkout.component.css']
})
export class SecureCheckoutComponent {
  faLock = faLock;

  @Input() spcOscCode: String;

  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
}
