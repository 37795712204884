import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { updateQueryStringParam } from '../../common/utils';
import { MerchantResponseComponent } from '../../merchant-response/merchant-response.component';
import { GlobalAppData } from '../../models/global-app-data';
import { MerchantResponseData } from '../../models/merchant-models';
import { PaypalResponse } from '../../models/paypal';
import {OrderService} from "../../services/order.service";
import { PaypalService } from '../../services/paypal.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-paypal-stub',
  templateUrl: './paypalstub.component.html',
  styleUrls: ['./paypalstub.component.css']
})
export class PaypalStubComponent implements OnInit {
  paymentStatus: string;
  MAX_ALLOWED_ATTEMPTS = environment.MAX_PAYMENT_ALLOWED_ATTEMPTS;
  timerCheck: any;
  orderId: string;
  closeResult = '';
  @ViewChild('modalContent') public modalContentRef: TemplateRef<any>;
  @Input() stubUserChoice: '';
  @ViewChild(MerchantResponseComponent) merchantResponseComponent;
  oscConfig: string;
  amountTotal: number;

  constructor(private modalService: NgbModal,
    private gd: GlobalAppData,
    private paypalService: PaypalService,
    private sharedService: SharedService,
    private odService: OrderService) { }

  ngOnInit(): void {
    this.oscConfig = this.gd.oscConfig.oscCode;
    this.amountTotal = this.gd.orderTransaction.amount;
  }

  openPaypalPopup(): void {
    this.createPaypalOrder().then((orderId: string) => {
      if (orderId) {
        this.orderId = orderId;
        this.openModal(this.modalContentRef);
      }
      return orderId;
    }).catch((err) => {
      return null;
    });
  }

  openModal(modalContent) {
    this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title' , backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      // call PSL service
      const requestBody = {
        payPalOrderId: this.orderId,
        paymentResult: this.stubUserChoice // 'accept' : 'decline'
      };
      this.paypalService.postPaypalStubData(JSON.stringify(requestBody)).then((res: string) => {
        if (JSON.stringify(res) === 'true') {
          this.capturePayPalOrder(JSON.stringify(res));
        }
      }).catch((error) => {
        this.handlePaypalDecline(this.gd.attemptCount);
      });
    }, (reason) => {
      this.handlePaypalDecline(this.gd.attemptCount);
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  capturePayPalOrder(data: any): void {
    try {
      this.paypalService.capturePaypalOrder(this.gd.transId)
        .then((res: PaypalResponse) => {
          if (res.status === 'COMPLETED') {
            this.redirectToMerchantSuccessURL();
          } else {
            this.handlePaypalDecline(this.gd.attemptCount);
          }
        }).catch((err) => {
        });
    } catch (err) {
    }
  }

  createPaypalOrder(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.gd.attemptCount > 1 && this.gd.attemptCount <= environment.MAX_PAYMENT_ALLOWED_ATTEMPTS) {
        this.sharedService.getNewTransactionId(this.gd.transId)
          .then((newTransId: string) => {
            this.gd.transId = newTransId;
            updateQueryStringParam('t', newTransId);
            return this.paypalService.getPaypalOrderId(newTransId)
              .then((ppRes: PaypalResponse) => {
                this.gd.attemptCount += 1;
                sessionStorage.setItem('page', this.gd.attemptCount.toLocaleString());
                resolve(ppRes.paypalOrderId);
              })
              .catch((err) => {
                reject(JSON.stringify(err));
              });
          }).catch((error) => {
            reject(JSON.stringify(error));
          });
      } else {
        return this.paypalService.getPaypalOrderId(this.gd.transId)
          .then((ppRes: PaypalResponse) => {
            this.gd.attemptCount += 1;
            sessionStorage.setItem('page', this.gd.attemptCount.toLocaleString());
            resolve(ppRes.paypalOrderId);
          })
          .catch((err) => {
            reject(JSON.stringify(err));
          });
      }
    });
  }

  handlePaypalDecline(pmtAttemptCount: number): void {
    if (pmtAttemptCount <= this.MAX_ALLOWED_ATTEMPTS) {
      this.paymentStatus = 'declined';
    } else {
      this.redirectToMerchantRejectURL();
    }
  }

  redirectToMerchantSuccessURL(): void {
    let merchantSuccessURL = this.gd.orderTransaction.order.merchantSuccessURL;
    if (!merchantSuccessURL) {
      merchantSuccessURL = this.gd.oscConfig.successUrl;
    }
    this.redirectToMerchant(merchantSuccessURL);
  }

  redirectToMerchantRejectURL(): void {
    let merchantRejectURL = this.gd.orderTransaction.order.merchantRejectURL;
    if (!merchantRejectURL) {
      merchantRejectURL = this.gd.oscConfig.rejectUrl;
    }
    this.redirectToMerchant(merchantRejectURL);
  }

  redirectToMerchant(url: string): void {
    sessionStorage.clear()
    const paymentActionData = {
      url: url,
      orderTransaction: this.gd.orderTransaction,
      merchantPaymentReq: {},
    } as MerchantResponseData;
    this.merchantResponseComponent.sendResponseToMerchant('PAYPAL', paymentActionData);
  }

  sendTxRejectStatus(): void {
    this.odService.sendTxRejectStatus(null);
  }
}
