import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  TransactionProgress,
  TransactionStatus,
} from '../models/enums/constants';
import { OrderService } from '../services/order.service';
import {
  Subscription,
  catchError,
  filter,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { Transaction } from '../models/transaction';
import { MerchantResponseComponent } from '../merchant-response/merchant-response.component';
import { MerchantResponseData } from '../models/merchant-models';
import { GlobalAppData } from '../models/global-app-data';
import { getQueryParamByName } from '../common/utils';
import { SessionService } from '../services/session.service';
import { Session } from '../models/session';

@Component({
  selector: 'app-transaction-progress',
  templateUrl: './transaction-progress.component.html',
  styleUrls: ['./transaction-progress.component.css'],
})
export class TransactionProgressComponent implements OnInit, OnDestroy {
  dataSubscription: Subscription;
  hideTimer: boolean;
  @ViewChild(MerchantResponseComponent) merchantResponseComponent;
  validTransaction: boolean;
  constructor(
    private orderService: OrderService,
    public gd: GlobalAppData,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    const jSession = getQueryParamByName('jSession');
    if (getQueryParamByName('t') && jSession) {
      this.manageSession(jSession);
    }
  }

  /* countdown timer shown on page while waiting to get updated transaction status */
  startCountDown() {
    let countDownMinutes = TransactionProgress.countDownMinutes * 60 * 1000;
    const interval = setInterval((): void => {
      countDownMinutes = countDownMinutes - 1000;
      if (countDownMinutes < 0) {
        this.hideTimer = true;
        clearInterval(interval);
      }
      // Time calculations for minutes and seconds
      if (countDownMinutes >= 0) {
        const minutes = Math.floor(
          (countDownMinutes % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((countDownMinutes % (1000 * 60)) / 1000);
        document.getElementById('countdown-number').innerHTML =
          minutes + 'm ' + seconds + 's ';
      }
    }, 1000);
  }

  /* get transaction status every 60 secs for a success or failure reply up to 8 mins total.
  EPMS will redirect the customer/send a postback to eREG as soon as EPMS gets a success or failure response.
After 8 mins, call api to get status every 5 secs for 2 mins.
If no changed status after 2 mins, will update transaction status incomplete and redirect with response */
  getTransactionStatus(interval, countDown, timeout?) {
    this.dataSubscription = timer(0, interval * 1000) // Repeat every 'interval' seconds
      .pipe(
        switchMap(() => {
          return this.orderService.getOrder().pipe(
            catchError((err) => {
              console.error(err);
              return of(undefined);
            })
          );
        }),
        filter((data) => data !== undefined)
      )
      .pipe(
        takeUntil(
          timer(countDown * 60 * 1000 + 3000).pipe(
            // countDownMinutes plus 3 seconds buffer to call api to get status at last minute
            tap(() => {
              if (!timeout) {
                this.getTransactionStatus(
                  TransactionProgress.intervalToGetStatusAfterTimeout,
                  TransactionProgress.countDownMinAfterTimeout,
                  true
                );
              } else {
                this.merchantResponseComponent.backToOrder(
                  'ccIncomplete',
                  '',
                  {}
                );
              }
            })
          )
        )
      )
      .subscribe({
        next: (data: Transaction) => {
          const transactionStatus =
            data?.transactionStatus?.toLocaleLowerCase();
          this.gd.orderTransaction = data;
          this.gd.sourceType = data?.order?.sourceType;
          this.gd.subscriptionDetail = data?.subscriptionDetail;
          if (
            transactionStatus &&
            transactionStatus !== TransactionStatus.awaited &&
            transactionStatus !== TransactionStatus.inProgress
          ) {
            const url =
              transactionStatus === TransactionStatus.success
                ? data.order.merchantSuccessURL
                : data.order.merchantErrorURL;
            this.redirectToMerchant(url, data.orderTransactionId);
          }
        },
        error: (err) => {
          console.log('error', err);
        },
      });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    this.sessionService.sessionExpiredSubject.unsubscribe();
  }

  /* Redirect to merchant with success or error response */
  redirectToMerchant(urlVal: string, orderTransaction): void {
    const paymentActionData = {
      url: urlVal,
      orderTransaction: orderTransaction,
      merchantPaymentReq: {},
    } as MerchantResponseData;
    this.merchantResponseComponent.sendResponseToMerchant(
      'FISERVINDIA',
      paymentActionData
    );
  }

  /* check if jsession expired */
  private manageSession(sessionId: string): void {
    this.sessionService.get(sessionId).subscribe({
      next: (session: Session) => {
        if (session.balanceTTLInSeconds > 0) {
          this.sessionService.sessionId = sessionId;
          this.sessionService.startCheckingSessionExpiry = true;
          this.sessionService.sessionExpiredSubject.subscribe(
            (isSessionExpired: boolean) => {
              if (isSessionExpired) {
                this.merchantResponseComponent.redirectToTimeoutURL('', {});
              } else {
                this.validTransaction = true;
                this.startCountDown();
                this.getTransactionStatus(
                  TransactionProgress.intervalInSecondsToGetStatus,
                  TransactionProgress.countDownMinutes
                );
              }
            }
          );
          setInterval(() => {
            this.merchantResponseComponent.backToOrder('timeout', '', {}); // update transaction and redirect to timeout
          }, session.balanceTTLInSeconds * 1000);
        } else {
          this.merchantResponseComponent.redirectToTimeoutURL('', {});
        }
      },
      error: (error: any) => {
        console.log('error', error);
      },
    });
  }
}
