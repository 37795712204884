import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { GlobalAppData } from '../models/global-app-data';
import { Transaction } from '../models/transaction';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-billing-name-information',
  templateUrl: './billing-name-information.component.html',
  styleUrls: ['./billing-name-information.component.css'],
})
export class BillingNameInformationComponent implements OnInit {
  @Input() formGroupName;
  @Input() orderData: Transaction;
  form: FormGroup;
  
  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
  @Input() spcOscCode: String;

  constructor(private rootFormGroup: FormGroupDirective, public gd: GlobalAppData) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  sameNamecheckbox(event) {
    if (event?.target?.checked) {
      this.form.controls['firstName']?.setValue(
        this.orderData.shippingAddress.firstName
      );
      this.form.controls['lastName']?.setValue(
        this.orderData.shippingAddress.lastName
      );
    } else {
      this.form.reset()
    }
  }
}
