import { Session } from './../models/session';
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(private http: HttpClient) {
  }

  public startCheckingSessionExpiry: boolean = false;
  public readonly sessionExpiredSubject = new BehaviorSubject(false);
  public sessionId: string = '';

  get(sessionId: string): Observable<Session> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url: string = environment.PAYMENT_UI + `/epms/payment/session/${sessionId}`;
    return this.http.get<Session>(url, httpOptions)
  }
}
