import { Injectable } from '@angular/core';
import { GlobalAppData } from '../models/global-app-data';
import { getQueryParamByName } from '../common/utils';

@Injectable({
  providedIn: 'root'
})
export class EpmsAppService {

  constructor() { }

  getSessionDetails(): GlobalAppData {
    const sessionData = sessionStorage.getItem('gd');
    if (sessionData) {
      const decryptSessionData = JSON.parse(Buffer.from(sessionData, 'base64').toString('utf-8'));
      const gdSessionData = Object.assign(new GlobalAppData(), decryptSessionData);
      if (gdSessionData.transId === getQueryParamByName('t')) {
        return gdSessionData;
      } else {
        return null;
      }
    }
  }
}
