import { Component, Input } from '@angular/core';
import { Constants } from '../../constants/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  @Input() spcOscCode: String;

  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;

  
  constructor() { }
}
