<div class="alert alert-danger" role="alert" *ngIf="paymentStatus && paymentStatus !== 'approved'">
    Your PayPal transaction was declined. Try again using a different payment method.
</div>
<div class="mb-2 mt-2 p-2">
    <!-- payment redirect form -->
    <app-merchant-response></app-merchant-response>
    <div class="card-body p-2">
        <div id="smart-button-container">

          <div
            data-pp-message
            data-pp-style-layout="text"
            [attr.data-pp-amount]=getAmount()
            data-pp-style-text-size="14"
            data-pp-style-text-align="center">
          </div>
            <div class="text-center mb-2">
                <strong>Log into</strong>
            </div>
            <div id="frame-paypal" style="text-align: center;">

                <div #paypal>

                </div>

            </div>
        </div>
    </div>
</div>
