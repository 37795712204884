import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { OrderComponent } from './order/order.component';
import { OrderService } from './services/order.service';
import { CreditCardService } from './services/credit-card.service';
import { PaymentService } from './services/payment.service';
import { PaypalService } from './services/paypal.service';
import { GlobalAppData } from './models/global-app-data';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdTooltipCustomclass } from './cvv-tooltip/cvv-tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrderTotalComponent } from './order-total/order-total.component';
import { AcceptedPaymentTypesComponent } from './accepted-payment-types/accepted-payment-types.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TelecheckComponent } from './telecheck/telecheck.component';
import { PaypalComponent } from './paypal/paypal.component';
import { SharedService } from './services/shared.service';
import { ConfirmEqualValidatorDirective } from './common/confirm-equal-validator.directive';
import { PaypalStubComponent } from './paypal/stub/paypalstub.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MerchantResponseComponent } from './merchant-response/merchant-response.component';
import { MerchantFieldsComponent } from './merchant-fields/merchant-fields.component';
import { HttpRequestInterceptor } from './http-request-interceptor/ http-request-interceptor';
import { UnicodeValidatorDirective } from './shared/directives/unicode-validate-directive';
import { AlipayComponent } from './alipay/alipay.component';
import { MainComponent } from './main/main.component';
import { TransactionProgressComponent } from './transaction-progress/transaction-progress.component';
import { BillingNameInformationComponent } from './billing-name-information/billing-name-information.component';
import { BillingAddressInformationComponent } from './billing-address-information/billing-address-information.component';
import { AlipayLearnMoreDialogComponent } from './alipay-learn-more/alipay-learn-more.component';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { SecureCheckoutComponent } from './secure-checkout/secure-checkout.component';
import { SegmentService } from './services/segment.service';

@NgModule({
  declarations: [
    AppComponent,
    OrderComponent,
    CreditCardComponent,
    HeaderComponent,
    FooterComponent,
    CheckoutComponent,
    OrderInfoComponent,
    NgbdTooltipCustomclass,
    OrderTotalComponent,
    AcceptedPaymentTypesComponent,
    TelecheckComponent,
    PaypalComponent,
    ConfirmEqualValidatorDirective,
    PaypalStubComponent,
    MerchantResponseComponent,
    MerchantFieldsComponent,
    UnicodeValidatorDirective,
    AlipayComponent,
    BillingNameInformationComponent,
    BillingAddressInformationComponent,
    AlipayLearnMoreDialogComponent,
    TransactionProgressComponent,
    MainComponent,
    SecureCheckoutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    AppRoutingModule,
  ],
  providers: [
    GlobalAppData,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    OrderService,
    CreditCardService,
    PaymentService,
    PaypalService,
    SharedService,
    provideHttpClient(withInterceptorsFromDi()),
    SegmentService
  ],
  bootstrap: [MainComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
