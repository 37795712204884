import { Injectable } from '@angular/core';
import { GlobalAppData } from '../models/global-app-data';
import { CurrencyConversionResponse } from '../models/payment';

declare let analytics: any;

@Injectable({
  providedIn: 'root'
})
export class SegmentService {

  constructor(private gd: GlobalAppData) { }

  identify() {
    if (this.gd.oscConfig.sendSegmentEventIndicator) {
      const orderDetails = this.gd.orderTransaction?.order;
      analytics?.identify(orderDetails.customerID, {
        name: `${orderDetails?.shippingAddress?.firstName} ${orderDetails?.shippingAddress?.lastName}`,
        email: `${orderDetails?.shippingContact?.email}`
      }, this.pageReferrerAttr());
    }
  }

  track(eventType: string, cardType?: string, dcc?: CurrencyConversionResponse, pgm?: Map<any, any>) {
    if (this.gd.oscConfig.sendSegmentEventIndicator) {
      analytics?.track(eventType, this.prepareTrackProps(cardType, dcc, pgm), this.pageReferrerAttr())
    }
  }

  private pageReferrerAttr(): any {
    return {
      page: {
        referrer: `${window.location.origin}`,
        url: `${window.location.href}`,
        name: `EPMS Checkout`
      }
    };
  }

  private prepareTrackProps(cardType?: string, dcc?: CurrencyConversionResponse, pgm?: Map<any, any>): any {
    const trackProps = {} as any;
    trackProps.payment_type = cardType || this.gd.paymentType;
    trackProps.customer_id = this.gd.orderTransaction?.order?.customerID;
    trackProps.order_id = this.gd.orderTransaction?.programSpecificKey;
    trackProps.osc_session_id = this.gd.orderTransaction?.order?.oscSessionId;
    trackProps.payment_system_id = this.gd.orderTransaction?.order.epmsOrderId;
    trackProps.attempt = this.gd.attemptCount;
    trackProps.payment_amount = this.gd.orderTransaction?.order?.orderAmount;
    trackProps.basecurrency = this.gd.orderTransaction?.order?.baseCurrencyCode;
    trackProps.epmsOrderId = this.gd.orderTransaction?.order?.epmsOrderId;
    trackProps.epmsTxnId = this.gd.transId;
    if (dcc) {
      trackProps.foreign_currency_amount = dcc.foreignCurrencyGrandTotalAmount;
      trackProps.foreign_currency_code = dcc.foreignCurrency;
    }
    if(pgm) {
      trackProps.pgm_response_code = pgm.get('pgmResponseCode');
      trackProps.pgm_response_message = pgm.get('pgmResponseMessage');
      trackProps.final_response = pgm.get('decision');
    }
    return trackProps;
  }

}
