import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { Constants } from '../../constants/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @ViewChild('footer') footer: ElementRef;
  @Input() spcOscCode: String;

  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;

  constructor(){}

  navigateToMainContent(contentElementName) {
    try {
      if (contentElementName === 'top') {
        window.scroll(0, 0);
        return;
      }
      const elements = document.getElementById(contentElementName);
      if (elements) {
        elements.scrollIntoView();
        elements.focus();
      }
    } finally {
    }
  }

  navigateToFooter(footerName) {
    const bottom = document.getElementById(footerName);
    if (bottom) {
      bottom.scrollIntoView();
      bottom.focus();
    }
  }




}