export enum EventTypes {
    ccSelected = 'CreditCard Selected',
    ccSubmitted = 'CreditCard Submitted',
    dccOffered = 'DCC Offered',
    dccNotOffered = 'DCC Not Offered',
    dccSubmitted = 'DCC Submitted',
    ccTokenSuccess = 'CreditCard Token Success',
    ccTokenError = 'CreditCard Token Error',
    ccSubmitSuccess = 'CreditCard Submit Success',
    ccSubmitError = 'CreditCard Submit Error',
    threedsForm = 'CreditCard Form 3DS',
    challengeForm = 'CreditCard Challenge 3DS',
    ppSelected = 'PayPal Selected',
    ppSubmitted = 'PayPal Submitted',
    ppCancel = 'PayPal Cancelled',
    ppError = 'PayPal Error',
    ppApproved = 'PayPal Approved',
    alipySelected = 'Alipay Selected',
    alipySubmitted = 'Alipay Submitted',
    pmtCompleted = 'Payment Completed'
}